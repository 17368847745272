import { Alert, Box, Button, Sheet, Typography } from "@mui/joy";
import moment from "moment-timezone";
import { Playing } from "../atoms/playing";
import { Tag } from "../atoms/tag";
import { Engagement, Piece } from "../entities/missionMap/missionMap";
import { Memo } from "../organisms/memo";
import { useMissionMap } from "../templates/answerTemplate";

const LEFT_HEADER_WIDTH = 120;

/**
 *
 * @returns {ReactElement} PieceLineItem page
 */
export function EngagementLineItem({
  engagement,
  token,
  openProject,
  projectInfo,
}: {
  engagement: Engagement;
  token: string;
  openProject?: () => void;
  projectInfo?: boolean;
}) {
  let hidePlayingPiece = true;
  const mission = useMissionMap(token);

  const pieces = mission?.Pieces;

  engagement.Plays.forEach((p) => {
    const piece = pieces ? pieces[p.PieceNumber - 1] : undefined;
    if (piece?.Title || piece?.Composer) {
      hidePlayingPiece = false;
    }
  });

  // Parse the ISO UTC string
  const utcDate = moment.utc(engagement.DateFromUTC);

  // Convert to the local timezone
  const localDate = utcDate.tz(engagement.VenueTzName);

  return (
    <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column" }}>
      {projectInfo ? (
        <Sheet
          variant="outlined"
          sx={{ display: "flex", p: 1, mb: 1, borderRadius: 8 }}
        >
          <Typography
            startDecorator={
              mission?.ProjectLogo ? (
                <img src={mission?.ProjectLogo} style={{ height: 30 }} />
              ) : undefined
            }
            level="title-md"
          >
            <i>{mission?.ProjectName}</i>
          </Typography>
        </Sheet>
      ) : (
        []
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          gap: 1,
          width: "100%",
          textAlign: "left",
        }}
      >
        <Tag sx={{ width: LEFT_HEADER_WIDTH, flexShrink: 0 }}>
          <Box sx={{ textAlign: "center" }}>
            {engagement.WeekDay.toUpperCase()}
            <br />
            <Typography level="title-lg">
              {engagement.Month} {engagement.Day}
            </Typography>
            <br />
            <Typography level="body-xs" sx={{ fontSize: 11 }}>
              {engagement.HourRange}
            </Typography>
            <br />
            <Typography level="body-xs" sx={{ fontSize: 11 }}>
              {localDate.format("z")}
            </Typography>
          </Box>
        </Tag>
        <Box sx={{ flexGrow: 1 }}>
          <Typography level="body-sm" sx={{ color: "black" }}>
            {engagement.Title}
          </Typography>
          <Typography level="body-xs">{engagement.VenueName}</Typography>
          <Typography level="body-xs">{engagement.VenueAddress}</Typography>
        </Box>
        <Playing playing={engagement.Playing} />
      </Box>
      {engagement?.Plays?.length && pieces && !hidePlayingPiece ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: 1,
            textAlign: "left",
          }}
        >
          <Box sx={{ width: LEFT_HEADER_WIDTH, flexShrink: 0 }}>
            <Typography level="body-xs">Playing Pieces:</Typography>
          </Box>
          <Box>
            {engagement?.Plays?.map((e) => {
              const piece = pieces ? pieces[e.PieceNumber - 1] : undefined;
              if (e.Playing)
                return (
                  <Typography
                    level="body-xs"
                    key={e.PieceNumber}
                    sx={{ color: "black" }}
                  >
                    • <i>{piece?.Title}</i>, {piece?.Composer}
                  </Typography>
                );
            })}
          </Box>
        </Box>
      ) : (
        []
      )}
      {engagement.Memo ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            textAlign: "left",
            gap: 1,
          }}
        >
          <Box sx={{ width: LEFT_HEADER_WIDTH, flexShrink: 0 }}>
            <Typography level="body-xs">Memo:</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Memo token={token} compact memo={engagement.Memo} />
          </Box>
        </Box>
      ) : (
        []
      )}
      {engagement.Chairs?.length ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: 1,
            textAlign: "left",
          }}
        >
          <Box sx={{ width: LEFT_HEADER_WIDTH, flexShrink: 0 }}>
            <Typography level="body-xs">Additional info:</Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: 1, flex: 1, flexDirection: "column" }}
          >
            {engagement?.Chairs?.map((e) => {
              const sectionInfo = [];
              if (e.SectionName) sectionInfo.push(`Section: ${e.SectionName}`);
              if (e.SectionRole) sectionInfo.push(`Role: ${e.SectionRole}`);
              // if (e.SectionOrder) sectionInfo.push(`Chair# ${e.SectionOrder}`);
              return (
                <Alert
                  size="sm"
                  color="warning"
                  key={e.PieceNumber}
                  sx={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "stretch",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography level="body-xs" sx={{ color: "inherit" }}>
                      Chair for Piece #{e.PieceNumber}
                    </Typography>
                    {sectionInfo.length ? (
                      <Tag
                        startDecorator={<i className="fa-solid fa-chair"></i>}
                      >
                        {sectionInfo.join(", ")}
                      </Tag>
                    ) : (
                      []
                    )}
                  </Box>
                  {e.Memo ? (
                    <Memo token={token} warning compact memo={e.Memo} />
                  ) : (
                    []
                  )}
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Typography level="body-xs" sx={{ color: "inherit" }}>
                      Instrument{e.Instruments.length > 1 ? "s" : ""}:
                    </Typography>
                    {e.Instruments.map((i) => (
                      <Tag key={i}>{i}</Tag>
                    ))}
                  </Box>
                </Alert>
              );
            })}
          </Box>
        </Box>
      ) : (
        []
      )}
      {openProject ? (
        <Button
          endDecorator={<i className="fa-solid fa-arrow-right"></i>}
          onClick={() => openProject()}
          sx={{ mt: 1 }}
          size="lg"
        >
          View Project Details
        </Button>
      ) : (
        []
      )}
    </Box>
  );
}
