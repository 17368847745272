import { Box, Button, CircularProgress, Sheet, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ConfettiCenterContext } from "../context/confettiCenter/confettiCenter";
import MissionMap_Entity, {
  Inspector,
  Interactor,
} from "../entities/missionMap/missionMap";
import { Contacts } from "../organisms/contact";
import { DressCode } from "../organisms/dressCode";
import { Footer } from "../organisms/footer";
import { Greetings } from "../organisms/greetings";
import { Links } from "../organisms/links";
import { ProjectNotes } from "../organisms/projectNotes";
import { Repertoire } from "../organisms/repertoire";
import { Response } from "../organisms/response";
import { Schedule } from "../organisms/schedule";
import { Wage } from "../organisms/wage";
import { useAnswerMutation, useGetMissionMapQuery } from "../redux/missionMap";
import {
  inspectorsSelector,
  selectedInteractorSelector,
  selectedInteractorJobStageIDSelector,
  setSelectedInteractor,
  setSelectedInteractorJobStageID,
} from "../redux/rhapsodyReducer";
import { useUploadFileMutation } from "../redux/upload";
import Intercom from "@intercom/messenger-js-sdk";
import { useSearchParams } from "react-router-dom";
import { ProjectRosters } from "../organisms/projectRosters";
import { Messages } from "../organisms/messages";
import { Paper } from "../atoms/paper";

export function useMissionMap(token: string): MissionMap_Entity | undefined {
  const { data } = useGetMissionMapQuery(token ?? "", { skip: !token });

  return data;
}

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function AnswerTemplate({
  token,
  hideResponse,
  hideLogo,
  hideFooter,
}: {
  token: string;
  hideLogo?: boolean;
  hideResponse?: boolean;
  hideFooter?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const mission = useMissionMap(token);
  const selectedInteractor = useSelector(selectedInteractorSelector);
  const selectedInteractorJobStageID = useSelector(
    selectedInteractorJobStageIDSelector
  );
  const [loading, setLoading] = useState(false);
  const [upload] = useUploadFileMutation();
  const inspectors = useSelector(inspectorsSelector);
  const confettiCenter = useContext(ConfettiCenterContext);
  const dispatch = useDispatch();
  const [answer] = useAnswerMutation();

  useEffect(() => {
    if (selectedInteractor && selectedInteractorJobStageID) {
      handleSubmit(selectedInteractor);
    }
  }, [selectedInteractor, selectedInteractorJobStageID]);

  const prepareInspectors = async () => {
    setLoading(true);
    const ret: Inspector[] = [];
    for (const key in inspectors) {
      if (Object.hasOwn(inspectors, key)) {
        const inspector = inspectors[key];

        if (inspector.message) {
          if (inspector.kind === "file") {
            for (const key in inspector.message) {
              if (Object.hasOwn(inspector.message, key)) {
                const url = await upload(inspector.message[key]).unwrap();
                ret.push({
                  ...inspector,
                  message: url,
                  title: inspector.message[key]?.name,
                });
              }
            }
          } else if (inspector.kind === "signature") {
            const url = await upload(inspector.message).unwrap();
            ret.push({ ...inspector, message: url });
          } else {
            ret.push({ ...inspector, message: `${inspector.message}` });
          }
        }
      }
    }

    return ret;
  };

  const handleSubmit = async (i: Interactor) => {
    dispatch(setSelectedInteractor(i));
    const inspectors = await prepareInspectors();
    if (mission?.PublicToken)
      answer({
        mercuryInteractorID: i.ID,
        mercuryInspectors: inspectors,
        jobStageID: selectedInteractorJobStageID,
        publicToken: mission?.PublicToken,
      })
        .unwrap()
        .then((e) => {
          dispatch(setSelectedInteractor());
          dispatch(setSelectedInteractorJobStageID());
          setLoading(false);
        });
    setSearchParams({ stage: i.Name.toLowerCase(), token: token ?? "" });
    if (i.Color?.toLowerCase() === "#4caf50") confettiCenter.show();
  };

  if (!mission) return <Box />;

  Intercom({
    app_id: "arj556zn",
    hide_default_launcher: true,
    // user_id: me.uuid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: mission.MusicianName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    // email: mission., // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: me.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: mission?.CompanyColor
          ? mission?.CompanyColor
          : "rgb(2, 28, 51)",
        alignItems: "center",
        position: "relative",
        flex: 1,
      }}
    >
      <Helmet>
        <meta
          name="theme-color"
          content={
            mission?.CompanyColor ? mission?.CompanyColor : "rgb(2, 28, 51)"
          }
        />
        <title>{mission?.CompanyName}</title>
      </Helmet>
      {mission?.Messages.length ? <Messages token={token} /> : []}
      <Box
        sx={{
          maxWidth: 480,
          width: "100%",
          p: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {!hideLogo ? (
          <img
            src={mission?.CompanyLogo}
            style={{ height: 70, margin: "auto" }}
          />
        ) : (
          []
        )}
        {mission !== undefined ? (
          <Greetings hideResponse={hideResponse} token={token} />
        ) : (
          []
        )}
        {mission?.Interactors?.length && !hideResponse ? (
          <Response token={token} />
        ) : (
          []
        )}
        {mission?.Pieces?.length ? <Repertoire token={token} /> : []}
        {mission?.Engagements?.length ? <Schedule token={token} /> : []}
        {mission?.ProjectRosters?.length ? (
          <ProjectRosters token={token} />
        ) : (
          []
        )}
        {mission?.Links?.length ? <Links token={token} /> : []}
        {mission?.DressCode?.length ? <DressCode token={token} /> : []}
        {mission?.Wage ? <Wage token={token} /> : []}
        {mission?.Contacts?.length ? <Contacts token={token} /> : []}
        {mission?.ProjectNotes ? <ProjectNotes token={token} /> : []}
        {/* {getMobileOperatingSystem() ? <DownloadApp /> : []} */}
      </Box>
      {!hideFooter ? <Footer token={token} /> : []}
      {selectedInteractor && !selectedInteractorJobStageID ? (
        <Box
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 2000,
            width: "100vw",
            padding: "16px",
            background: selectedInteractor.Color,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              level="body-lg"
              sx={{ color: "inherit", fontWeight: 700 }}
            >
              You are about to {selectedInteractor.Name}, please confirm
            </Typography>
            <Typography
              level="body-md"
              sx={{ color: "inherit", opacity: 0.58 }}
            >
              {selectedInteractor.Description}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              fullWidth
              color="neutral"
              sx={{
                color: "inherit",
                "&:hover": { background: "rgba(255,255,255,0.1)" },
                "&:active": { background: "rgba(255,255,255,0.2)" },
              }}
              onClick={() => {
                dispatch(setSelectedInteractor());
                dispatch(setSelectedInteractorJobStageID());
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              style={{
                flex: 1,
                marginLeft: 4,
                color: selectedInteractor.Color,
                background: "white",
                textTransform: "none",
              }}
              startDecorator={
                selectedInteractor?.Icon ? (
                  <i className={selectedInteractor.Icon}></i>
                ) : undefined
              }
              onClick={() => handleSubmit(selectedInteractor)}
            >
              {selectedInteractor.Name}
            </Button>
          </Box>
        </Box>
      ) : (
        []
      )}
      {selectedInteractor && !selectedInteractorJobStageID ? (
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.6)",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: 999,
          }}
          onClick={() => dispatch(setSelectedInteractor())}
        />
      ) : (
        []
      )}
      {loading ? (
        <Box
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.6)",
            height: "100vh",
            width: "100%",
            top: 0,
            zIndex: 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(10px)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
