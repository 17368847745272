import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemDecorator,
  Tooltip,
  Typography,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAnswerMutation } from "../redux/missionMap";
import { useMissionMap } from "../templates/answerTemplate";
import { Greetings } from "./greetings";
import { Response } from "./response";
import { getContrastYIQ } from "../helpers";
import { useDispatch } from "react-redux";
import {
  setSelectedInteractor,
  setSelectedInteractorJobStageID,
} from "../redux/rhapsodyReducer";

/**
 *
 * @returns {ReactElement} Messages page
 */
export function Messages({ token }: { token: string }) {
  const [answer] = useAnswerMutation();
  const mission = useMissionMap(token);
  const [open, setOpen] = useState(false);
  const [selectedJobStageID, setSelectedJobStageID] = useState<number>();
  const messages = mission?.Messages;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedJobStageID) {
      dispatch(setSelectedInteractor());
      dispatch(setSelectedInteractorJobStageID());
    }
  }, [selectedJobStageID]);
  const blueBubble = (
    <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: 10,
        background: "#027AFF",
      }}
    ></Box>
  );

  const color = getContrastYIQ(mission?.CompanyColor ?? "#000000");

  const selectedMessage = messages?.find(
    (m) => m.JobStageID === selectedJobStageID
  );

  return (
    <Box sx={{ p: 2, display: "flex", gap: 1, overflow: "auto" }}>
      {mission?.Messages.filter((m) => !m.Acknowledged).map((m) => (
        <Button
          onClick={() => setSelectedJobStageID(m.JobStageID)}
          endDecorator={!m.Acknowledged ? blueBubble : undefined}
          startDecorator={
            <img
              style={{
                width: 18,
                filter:
                  m.JobStageID === selectedJobStageID
                    ? "brightness(0) invert(1)"
                    : undefined,
              }}
              src={m.Icon}
            />
          }
          sx={{
            zIndex: 1302,
            background:
              m.JobStageID === selectedJobStageID ? m.Color : `${color}22`,
            "&:hover": {
              background:
                m.JobStageID === selectedJobStageID ? m.Color : `${color}33`,
            },
            flexShrink: 0,
          }}
          size="sm"
          key={m.JobStageID}
        >
          <Box
            sx={{
              textAlign: "left",
              color:
                m.JobStageID === selectedJobStageID || selectedJobStageID
                  ? "white"
                  : color,
            }}
          >
            {m.Name}
            <Typography level="body-xs" sx={{ fontSize: 8, color: "inherit" }}>
              {moment(m.CreatedAt).format("lll")}
            </Typography>
          </Box>
        </Button>
      ))}
      <Tooltip
        arrow
        variant="outlined"
        open={open}
        title={
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <List sx={{ maxWidth: 280, width: "100vw" }}>
              {messages?.map((m) => (
                <ListItemButton
                  selected={selectedJobStageID == m.JobStageID}
                  key={m.JobStageID}
                  onClick={() => {
                    setSelectedJobStageID(m.JobStageID);
                    setOpen(false);
                  }}
                >
                  <ListItemDecorator>
                    <img
                      style={{
                        width: 18,
                      }}
                      src={m.Icon}
                    />
                  </ListItemDecorator>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography level="body-md">{m.Name}</Typography>
                    <Typography level="body-xs">
                      {m.CreatedBy} on {moment(m.CreatedAt).format("lll")}
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <ListItemDecorator>
                    {!m.Acknowledged ? blueBubble : []}
                  </ListItemDecorator>
                </ListItemButton>
              ))}
            </List>
          </ClickAwayListener>
        }
      >
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            zIndex: 1302,
            background: `${color}22`,
            "&:hover": {
              background: `${color}33`,
            },
            color: selectedJobStageID ? "white" : color,
          }}
          size="sm"
        >
          <i className="fa-solid fa-inbox"></i>
        </IconButton>
      </Tooltip>
      {selectedJobStageID ? (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedJobStageID(undefined);
          }}
          style={{
            position: "fixed",
            background: "rgba(0,0,0,0.6)",
            height: "100vh",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1301,
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            backdropFilter: "blur(10px)",
          }}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{
              mt: "78px",
              gap: 2,
              display: "flex",
              width: "100%",
              flexDirection: "column",
              maxWidth: 480,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography level="body-sm" sx={{ color: "white" }}>
                {selectedMessage?.CreatedBy} on{" "}
                {moment(selectedMessage?.CreatedAt).format("lll")}:
              </Typography>
            </Box>
            <Greetings
              token={token}
              overwriteMission={selectedMessage?.Data}
              hideResponse
            />
            {selectedMessage?.Data.Interactors?.length ? (
              <Response
                token={token}
                jobStageID={selectedMessage?.JobStageID}
                overwriteMission={selectedMessage?.Data}
              />
            ) : (
              []
            )}
            {!selectedMessage?.Acknowledged &&
            (selectedMessage?.Data?.Interactors ?? [])?.length == 0 ? (
              <Button
                startDecorator={<i className="fa-solid fa-eye"></i>}
                variant="soft"
                size="lg"
                onClick={() => {
                  answer({
                    mercuryInteractorID: selectedMessage?.JobStageID,
                    jobStageID: selectedMessage?.JobStageID,
                    publicToken: mission?.PublicToken,
                  });
                }}
              >
                Mark as read
              </Button>
            ) : (
              []
            )}
            {selectedMessage?.Inspectors?.map((i) => (
              <Box key={i.ID} sx={{ textAlign: "center" }}>
                {i.Kind === "file" ? (
                  <Button
                    onClick={() => window.open(i.Message)}
                    sx={{ mr: 1 }}
                    color="primary"
                    variant="solid"
                  >
                    {i.Title}
                  </Button>
                ) : (
                  <Typography
                    startDecorator={
                      <i className="fa-solid fa-check-double"></i>
                    }
                    level="body-sm"
                    sx={{ color: "white" }}
                  >
                    {i.Message} by {i.CreatedBy} on{" "}
                    {moment(i.CreatedAt).format("lll")}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}
