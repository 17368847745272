import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Drawer,
  ModalClose,
  Sheet,
  Typography,
  useTheme,
} from "@mui/joy";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Footer } from "../organisms/footer";
import { Response } from "../organisms/response";
import { SeasonCalendar } from "../organisms/seasonCalendar";
import { SeasonGreetings } from "../organisms/seasonGreetings";
import { SeasonMissions } from "../organisms/seasonMissions";
import {
  inspectorsSelector,
  selectedInteractorSelector,
  selectedTokenSelector,
  setSelectedInteractor,
  setSelectedToken,
} from "../redux/rhapsodyReducer";
import { AnswerTemplate, useMissionMap } from "../templates/answerTemplate";
import { Inspector, Interactor } from "../entities/missionMap/missionMap";
import { useContext, useState } from "react";
import { useUploadFileMutation } from "../redux/upload";
import { useAnswerMutation } from "../redux/missionMap";
import { ConfettiCenterContext } from "../context/confettiCenter/confettiCenter";
import { useMediaQuery } from "@mui/material";
import { Messages } from "../organisms/messages";

/**
 *
 * @returns {ReactElement} SeasonInvite page
 */
export function SeasonInvite() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const selectedToken = useSelector(selectedTokenSelector);
  const selectedInteractor = useSelector(selectedInteractorSelector);
  const mission = useMissionMap(token!);
  const inspectors = useSelector(inspectorsSelector);
  const [loading, setLoading] = useState(false);
  const [upload] = useUploadFileMutation();
  const [answer] = useAnswerMutation();
  const confettiCenter = useContext(ConfettiCenterContext);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(600));

  const prepareInspectors = async () => {
    setLoading(true);
    const ret: Inspector[] = [];
    for (const key in inspectors) {
      if (Object.hasOwn(inspectors, key)) {
        const inspector = inspectors[key];

        if (inspector.message) {
          if (inspector.kind === "file") {
            for (const key in inspector.message) {
              if (Object.hasOwn(inspector.message, key)) {
                const url = await upload(inspector.message[key]).unwrap();
                ret.push({
                  ...inspector,
                  message: url,
                  title: inspector.message[key]?.name,
                });
              }
            }
          } else if (inspector.kind === "signature") {
            const url = await upload(inspector.message).unwrap();
            ret.push({ ...inspector, message: url });
          } else {
            ret.push({ ...inspector, message: `${inspector.message}` });
          }
        }
      }
    }

    return ret;
  };

  const handleSubmit = async (i: Interactor) => {
    dispatch(setSelectedInteractor(i));
    const inspectors = await prepareInspectors();
    if (mission?.PublicToken)
      answer({
        mercuryInteractorID: i.ID,
        mercuryInspectors: inspectors,
        publicToken: mission?.PublicToken,
      })
        .unwrap()
        .then((e) => {
          setLoading(false);
        });
    setSearchParams({ stage: i.Name.toLowerCase(), token: token ?? "" });
    if (i.Color?.toLowerCase() === "#4caf50") confettiCenter.show();
  };

  if (!mission) return <Box />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: mission?.CompanyColor
          ? mission.CompanyColor
          : "rgb(2, 28, 51)",
        alignItems: "center",
        position: "relative",
        flex: 1,
      }}
    >
      <Helmet>
        <meta
          name="theme-color"
          content={
            mission.CompanyColor ? mission.CompanyColor : "rgb(2, 28, 51)"
          }
        />
        <title>{mission.CompanyName}</title>
      </Helmet>
      {mission?.Messages.length ? <Messages token={token ?? ""} /> : []}
      <Box
        sx={{
          maxWidth: 480,
          width: "100%",
          p: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <img src={mission.CompanyLogo} style={{ height: 70, margin: "auto" }} />
        <SeasonGreetings
          token={mission.PublicToken}
          seasonCover={mission.CoverURL}
          seasonName={mission.SeasonName}
        />
        {mission?.Interactors?.length ? <Response token={token ?? ""} /> : []}
        <SeasonMissions missions={mission.Projects} />
        <SeasonCalendar missions={mission.Projects} />
        <Footer token={mission.PublicToken} />
      </Box>
      {selectedInteractor ? (
        <Box
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 2000,
            width: "100vw",
            padding: "16px",
            background: selectedInteractor.Color,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              level="body-lg"
              sx={{ color: "inherit", fontWeight: 700 }}
            >
              You are about to {selectedInteractor.Name}, please confirm
            </Typography>
            <Typography
              level="body-md"
              sx={{ color: "inherit", opacity: 0.58 }}
            >
              {selectedInteractor.Description}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              fullWidth
              color="neutral"
              sx={{
                color: "inherit",
                "&:hover": { background: "rgba(255,255,255,0.1)" },
                "&:active": { background: "rgba(255,255,255,0.2)" },
              }}
              onClick={() => dispatch(setSelectedInteractor())}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              style={{
                flex: 1,
                marginLeft: 4,
                color: selectedInteractor.Color,
                background: "white",
                textTransform: "none",
              }}
              startDecorator={
                selectedInteractor?.Icon ? (
                  <i className={selectedInteractor.Icon}></i>
                ) : undefined
              }
              onClick={() => handleSubmit(selectedInteractor)}
            >
              {selectedInteractor.Name}
            </Button>
          </Box>
        </Box>
      ) : (
        []
      )}
      {selectedInteractor ? (
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.6)",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: 999,
          }}
          onClick={() => dispatch(setSelectedInteractor())}
        />
      ) : (
        []
      )}
      {loading ? (
        <Box
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.6)",
            height: "100vh",
            width: "100%",
            top: 0,
            zIndex: 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(10px)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        []
      )}
      <Drawer
        sx={{
          zIndex: 100002,
        }}
        onClose={() => dispatch(setSelectedToken(undefined))}
        open={selectedToken !== undefined}
        anchor="right"
        slotProps={{
          content: {
            sx: {
              width: "100vw",
              maxWidth: 480,
            },
          },
        }}
      >
        {sm ? (
          <Box
            sx={{
              zIndex: 100002,
              background: mission?.CompanyColor
                ? mission.CompanyColor
                : "rgb(2, 28, 51)",
            }}
          >
            <Button
              onClick={() => dispatch(setSelectedToken(undefined))}
              variant="plain"
              size="lg"
              startDecorator={<i className="fa-solid fa-chevron-left"></i>}
            >
              Back
            </Button>
          </Box>
        ) : (
          <ModalClose sx={{ zIndex: 9999 }} />
        )}
        {selectedToken ? (
          <AnswerTemplate
            token={selectedToken}
            hideResponse
            hideLogo
            hideFooter
          />
        ) : (
          []
        )}
      </Drawer>
    </Box>
  );
}
