import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Inspector, Interactor } from "../entities/missionMap/missionMap";

interface RhapsodyReducerState {
  selectedInteractor?: Interactor;
  selectedInteractorJobStageID?: number;
  selectedToken?: string;
  inspectors: {
    [i: number]: Inspector;
  };
  formOpen: { [key: string]: boolean };
}

const initialState = { inspectors: {}, formOpen: {} } as RhapsodyReducerState;

export const rhapsodyReducerSlice = createSlice({
  name: "rhapsody",
  initialState,
  reducers: {
    setSelectedInteractor(
      state,
      action: PayloadAction<Interactor | undefined>
    ) {
      state.selectedInteractor = action.payload;
    },
    setSelectedInteractorJobStageID(
      state,
      action: PayloadAction<number | undefined>
    ) {
      state.selectedInteractorJobStageID = action.payload;
    },
    setSelectedToken(state, action: PayloadAction<string | undefined>) {
      state.selectedToken = action.payload;
    },
    setFormOpen(
      state,
      action: PayloadAction<{ formID: FormID; isOpen: boolean }>
    ) {
      const { formID, isOpen } = action.payload;
      state.formOpen[formID] = isOpen;
    },
    setInspectors(
      state,
      action: PayloadAction<{
        [i: number]: Inspector;
      }>
    ) {
      state.inspectors = { ...state.inspectors, ...action.payload };
    },
  },
});

export const {
  setSelectedInteractor,
  setSelectedInteractorJobStageID,
  setInspectors,
  setFormOpen,
  setSelectedToken,
} = rhapsodyReducerSlice.actions;

export const selectedInteractorSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedInteractor,
  (s) => s
);

export const selectedInteractorJobStageIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedInteractorJobStageID,
  (s) => s
);

export const selectedTokenSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedToken,
  (s) => s
);

export const inspectorsSelector = createSelector(
  (s: RootState) => s.rhapsody.inspectors,
  (hover) => hover
);

export const formOpenSelector = (formID: FormID) => {
  return createSelector([(s) => s.rhapsody.formOpen], (s) => {
    return s[formID] ?? false;
  });
};

export type FormID = "askForRelease";
