import { Box, Typography } from "@mui/joy";
import { ReactElement } from "react";

/**
 *
 * @returns {ReactElement} NotFound route
 */
export default function NotFoundRoute(): ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        background: "#021B33",
      }}
    >
      <div className="no-signal"></div>
      <div className="frame">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: 4,
          flexDirection: "column",
        }}
      >
        <img
          style={{ height: 80 }}
          src="https://storage.googleapis.com/wid-rhapsody/images/logo_main-white.png"
        />
        <Box>
          <Typography sx={{ color: "white", fontWeight: 800 }} level="h4">
            404 Not Found
          </Typography>
          <Typography sx={{ color: "white" }}>
            The resource could not be found
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
