import { Alert, Box, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { Memo } from "./memo";
import { useMissionMap } from "../templates/answerTemplate";
import { alpha } from "@mui/material";
import { AskForRelease } from "./askForRelease";
import { MissionMap_Entity } from "../entities/missionMap";

export function Greetings({
  token,
  hideResponse,
  overwriteMission,
}: {
  token: string;
  hideResponse?: boolean;
  overwriteMission?: MissionMap_Entity;
}) {
  const _mission = useMissionMap(token);
  const mission = overwriteMission ?? _mission;
  const CurrentStage = mission?.CurrentStage;
  const Interactors = mission?.Interactors;

  return (
    <Paper>
      <Box>
        {mission?.ProjectLogo ? (
          <img
            src={mission?.ProjectLogo}
            style={{ height: 100, borderRadius: "8px" }}
          />
        ) : (
          []
        )}
        {!overwriteMission ? (
          <Typography level="title-lg">
            {mission?.EventStatusTitle} {mission?.CompanyName}
          </Typography>
        ) : (
          []
        )}
        <Typography level="body-sm">
          Hello {mission?.MusicianName},
          <div
            style={{
              textAlign: "center",
              width: `calc(100vw - 32px)`,
              maxWidth: 450,
              padding: 0,
            }}
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: mission?.Greetings ?? "" }}
          />
        </Typography>
      </Box>
      {!overwriteMission ? (
        <Typography level="title-lg" sx={{ mt: 1, mb: 1 }}>
          <i>&quot;{mission?.ProjectName}&quot;</i>
        </Typography>
      ) : (
        []
      )}
      {mission?.Memo ? <Memo memo={mission?.Memo} token={token} /> : []}
      {!Interactors || (Interactors.length === 0 && !hideResponse) ? (
        <Alert
          size="sm"
          sx={{
            background: CurrentStage?.Color
              ? alpha(CurrentStage.Color, 0.1)
              : "#9e9e9e",
          }}
        >
          <Typography
            startDecorator={
              CurrentStage?.Icon ? (
                <img style={{ height: 14 }} src={CurrentStage?.Icon} />
              ) : undefined
            }
            sx={{ color: CurrentStage?.Color }}
            level="body-sm"
          >
            {CurrentStage?.Name}
          </Typography>
        </Alert>
      ) : (
        []
      )}
      {!overwriteMission ? <AskForRelease token={token} /> : []}
    </Paper>
  );
}
