import { Box, Chip, Divider, Link, Typography } from "@mui/joy";
import { useMissionMap } from "../templates/answerTemplate";
import { show } from "@intercom/messenger-js-sdk";

function getContrastYIQ(hexcolor: string) {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export function Footer({ token }: { token: string }) {
  const mission = useMissionMap(token);

  const color = getContrastYIQ(mission?.CompanyColor ?? "#000000");

  return (
    <Box
      sx={{
        textAlign: "center",
        color: color,
        pb: 8,
        pt: 2,
        opacity: 0.7,
        background: mission?.CompanyColor,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
        <Typography
          level="body-md"
          sx={{ color: "inherit" }}
          endDecorator={
            <img
              onClick={() => window.open("https://www.rhapsody.la")}
              src={"https://storage.googleapis.com/rhapsody/Avatars/logo.svg"}
              style={{
                height: 25,
                cursor: "pointer",
                filter: color === "black" ? "invert(100%)" : undefined,
              }}
            />
          }
        >
          <b>
            <i>Powered by</i>
          </b>{" "}
        </Typography>
      </Box>
      <Typography level="body-xs" sx={{ color: "inherit", mt: 1 }}>
        You received this email because of {mission?.CompanyName}
        &apos;s account with Rhapsody.
        <br />
        If you no longer wish to receive Rhapsody calls from{" "}
        {mission?.CompanyName}, please contact them directly.
        <br />
        <Divider sx={{ mt: 1, mb: 1, background: color, opacity: 0.2 }} />
        Developed with love in Los Angeles, CA
        <br />
        <Link sx={{ color: "inherit" }} href="mailto:contact@rhapsody.la">
          contact@rhapsody.la
        </Link>
        <br />
        <Link sx={{ color: "inherit" }} href="https://www.rhapsody.la">
          www.rhapsody.la
        </Link>
        <br />
        <Chip
          startDecorator={<i className="fa-solid fa-comment"></i>}
          sx={{ mt: 2 }}
          onClick={show}
          size="sm"
        >
          Chat with Rhapsody Support
        </Chip>
      </Typography>
    </Box>
  );
}
