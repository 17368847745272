import {
  Box,
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  Divider,
  Link,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { darken } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { Paper } from "../atoms/paper";
import MissionMap_Entity, {
  Inspector,
  Interactor,
} from "../entities/missionMap/missionMap";
import {
  inspectorsSelector,
  setInspectors,
  setSelectedInteractor,
  setSelectedInteractorJobStageID,
} from "../redux/rhapsodyReducer";
import { useMissionMap } from "../templates/answerTemplate";

export function Response({
  token,
  overwriteMission,
  jobStageID,
}: {
  token: string;
  overwriteMission?: MissionMap_Entity;
  jobStageID?: number;
}) {
  const _mission = useMissionMap(token);
  const mission = overwriteMission ?? _mission;
  const inspectors = useSelector(inspectorsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mission?.Interactors.length) {
      dispatch(
        setInspectors(
          mission?.Interactors.reduce<{ [i: number]: Inspector }>((a, v) => {
            a[v.ID] = {
              id: v.ID,
              title: v.Name,
              icon: v.Icon,
              kind: v.Kind,
            };
            return a;
          }, {})
        )
      );
    }
  }, [mission]);

  const getInteractor = (i: Interactor) => {
    if (!inspectors[i.ID]) return <Box />;

    switch (i.Kind) {
      case "file":
        return (
          <Upload
            interactor={i}
            onDelete={(f) => {
              const files: File[] = inspectors[i.ID]?.message ?? [];
              dispatch(
                setInspectors({
                  ...inspectors,
                  [i.ID]: {
                    ...inspectors[i.ID],
                    message: files.filter(
                      (e) => e.name !== f.name && e.size !== f.size
                    ),
                  },
                })
              );
            }}
            onAdd={(e) => {
              const files: File[] = inspectors[i.ID]?.message ?? [];
              dispatch(
                setInspectors({
                  ...inspectors,
                  [i.ID]: { ...inspectors[i.ID], message: [...files, ...e] },
                })
              );
            }}
            files={inspectors[i.ID].message ?? []}
          />
        );
      case "signature":
        return (
          <Signature
            interactor={i}
            onChange={(s) => {
              dispatch(
                setInspectors({
                  ...inspectors,
                  [i.ID]: { ...inspectors[i.ID], message: s },
                })
              );
            }}
          />
        );
      case "download":
        return (
          <Button
            fullWidth
            variant="soft"
            color="neutral"
            startDecorator={i.Icon ? <i className={i.Icon} /> : undefined}
            endDecorator={<i className="fa-regular fa-arrow-down"></i>}
            onClick={() => {
              window.open(i.Description);
              dispatch(
                setInspectors({
                  ...inspectors,
                  [i.ID]: { ...inspectors[i.ID], message: "Downloaded" },
                })
              );
            }}
          >
            Download {i.Name}
          </Button>
        );
      case "checkbox":
        return (
          <Box sx={{ textAlign: "left" }}>
            <Checkbox
              checked={inspectors[i.ID].message ?? false}
              onChange={(e) =>
                dispatch(
                  setInspectors({
                    ...inspectors,
                    [i.ID]: { ...inspectors[i.ID], message: e.target.checked },
                  })
                )
              }
              label={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    level="body-sm"
                    sx={{ color: "black" }}
                    startDecorator={
                      i.Icon ? <i className={i.Icon} /> : undefined
                    }
                  >
                    {i.Name}
                  </Typography>
                  {i.Description ? (
                    <Typography level="body-xs">{i.Description}</Typography>
                  ) : undefined}
                </Box>
              }
            />
          </Box>
        );
      case "text":
        return (
          <Textarea
            value={inspectors[i.ID].message ?? ""}
            onChange={(e) =>
              dispatch(
                setInspectors({
                  ...inspectors,
                  [i.ID]: { ...inspectors[i.ID], message: e.target.value },
                })
              )
            }
            variant="plain"
            placeholder={i.Description}
            startDecorator={
              <Typography
                level="body-sm"
                sx={{ color: "black" }}
                startDecorator={i.Icon ? <i className={i.Icon} /> : undefined}
              >
                {i.Name}
              </Typography>
            }
          />
        );
      case "button":
        return (
          <Button
            onClick={() => {
              dispatch(setSelectedInteractor(i));
              if (jobStageID)
                dispatch(setSelectedInteractorJobStageID(jobStageID));
            }}
            key={i.Name}
            startDecorator={i.Icon ? <i className={i.Icon} /> : undefined}
            sx={{
              flex: 1,
              background: i.Color,
              "&:hover": {
                background: i.Color ? darken(i.Color, 0.1) : undefined,
              },
              "&:active": {
                background: i.Color ? darken(i.Color, 0.2) : undefined,
              },
            }}
          >
            {i.Name}
          </Button>
        );
        break;

      default:
        break;
    }
    return (
      <Sheet variant="soft" color="neutral" sx={{ borderRadius: "8px" }}>
        Interactor Not Supported
      </Sheet>
    );
  };

  const segueButtons = mission?.Interactors?.filter(
    (e) => e.Kind === "button" && e.SegueID
  );
  const otherInteractors = mission?.Interactors?.filter(
    (e) => !(e.Kind === "button" && e.SegueID)
  ).sort((a, b) => (a.Position ?? 0) - (b.Position ?? 0));

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">Your response is required</Typography>
        <Typography level="body-sm">
          Select from the options below your response for
          <br />
          {mission?.CompanyName}
          <i>&quot;{mission?.ProjectName}&quot;</i>
        </Typography>
      </Box>
      {otherInteractors?.length ? (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          {otherInteractors?.map((e) => (
            <Sheet
              key={e.ID}
              variant="soft"
              color="neutral"
              sx={{ p: 1, borderRadius: "8px", zIndex: 1300 }}
            >
              {getInteractor(e)}
            </Sheet>
          ))}
        </Box>
      ) : (
        []
      )}
      {segueButtons?.length ? (
        <Box
          sx={{ display: "flex", gap: 1, flexDirection: "row", width: "100%" }}
        >
          {segueButtons?.map((e) => getInteractor(e))}
        </Box>
      ) : (
        []
      )}
    </Paper>
  );
}

function Upload({
  files,
  onAdd,
  onDelete,
  interactor,
}: {
  files: File[];
  onAdd: (e: File[]) => void;
  onDelete: (e: File) => void;
  interactor: Interactor;
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onAdd(acceptedFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Box
        {...getRootProps()}
        sx={{
          padding: 1,
          borderRadius: 4,
          background: isDragActive ? "#bbdefb" : "f5f5f5",
        }}
      >
        <input {...getInputProps()} />
        <Typography level="h3">
          <i className="fa-duotone fa-cloud-arrow-up"></i>
        </Typography>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            startDecorator={
              interactor.Icon ? <i className={interactor.Icon} /> : undefined
            }
            level="title-lg"
          >
            {interactor.Name}
          </Typography>
        </Box>
        <Typography level="body-sm">{interactor.Description}.</Typography>
        <Link
          level="body-sm"
          endDecorator={<i className="fa-solid fa-arrow-up-right"></i>}
        >
          Browse Files
        </Link>
        <Typography level="body-xs">100Mb max</Typography>
      </Box>

      {files?.map((f) => (
        <Chip
          sx={{ background: "white" }}
          endDecorator={<ChipDelete onClick={() => onDelete(f)} />}
          key={f.size}
        >
          {f.name}
        </Chip>
      ))}
    </Box>
  );
}

function Signature({
  onChange,
  interactor,
}: {
  onChange: (s: any) => void;
  interactor: Interactor;
}) {
  // const [value, setValue] = useState<any>();
  const [clear, setClear] = useState(0);
  const ref: any = useRef();

  const handleChange = () => {
    const base64 = ref.current.toDataURL();
    const base64Data = base64.split(",")[1];

    // Convert the base64 data to binary data
    const binaryData = atob(base64Data);

    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "image/png" });

    // Create a File object from the Blob
    const file = new File([blob], "image.png", { type: "image/png" });

    onChange(file);
  };

  return (
    <Box sx={{ p: 0.5, textAlign: "left" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={
              interactor.Icon ? <i className={interactor.Icon} /> : undefined
            }
            level="body-sm"
            sx={{ color: "black" }}
          >
            {interactor.Name}
          </Typography>
          <Typography level="body-xs">{interactor.Description}</Typography>
        </Box>
        <Chip
          variant="outlined"
          onClick={() => {
            ref.current.clear();
          }}
          sx={{ background: "white" }}
          size="sm"
        >
          Clear Signature
        </Chip>
      </Box>
      <Divider />
      <Box sx={{ position: "relative", height: 200 }}>
        <Box
          sx={{ position: "absolute", top: 0, textAlign: "center", zIndex: 10 }}
        >
          <SignatureCanvas
            ref={ref}
            penColor="black"
            onEnd={handleChange}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          />
        </Box>
        <Typography
          level="h1"
          sx={{
            position: "absolute",
            top: 70,
            opacity: 0.1,
            zIndex: 0,
            margin: "auto",
            textAlign: "center",
            width: "100%",
          }}
        >
          Sign Here
        </Typography>
      </Box>
    </Box>
  );
}
