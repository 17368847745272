import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetMissionMapQuery } from "../redux/missionMap";
import { setSelectedInteractor } from "../redux/rhapsodyReducer";
import { AnswerTemplate } from "../templates/answerTemplate";
import { SeasonInvite } from "./season";
import { show } from "@intercom/messenger-js-sdk";

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function AnswerRoute() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const interactorID = searchParams.get("interactorID");
  const version = searchParams.get("version");
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useGetMissionMapQuery(token ?? "", {
    skip: !token,
  });

  useEffect(() => {
    if (interactorID && data) {
      dispatch(
        setSelectedInteractor(
          data.Interactors.find((i) => `${i.ID}` === interactorID)
        )
      );
    }
  }, [interactorID, data]);

  useEffect(() => {
    if (!token) {
      window.location.replace("https://rhapsody.la/");
    }
  }, [token]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          background: "#021B33",
        }}
      >
        <div className="no-signal"></div>
        <div className="frame">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            gap: 4,
            flexDirection: "column",
          }}
        >
          <img
            style={{ height: 80 }}
            src="https://storage.googleapis.com/wid-rhapsody/images/logo_main-white.png"
          />
          <Box>
            <Typography sx={{ color: "white", fontWeight: 800 }} level="h4">
              Invite Not Found
            </Typography>
            <Typography sx={{ color: "white" }}>
              Chat with us if you think it is a bug.
            </Typography>
          </Box>
          <Button onClick={show}>Chat with Support</Button>
        </Box>
      </Box>
    );
  }

  if ((token && token[0] === "M") || version === "3") {
    return <SeasonInvite />;
  }

  return <AnswerTemplate token={token ?? ""} />;
}
